import React from 'react';

import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';
import SEO from '../components/SEO';

const ContactUsPage = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <ContactForm />
    </Layout>
  );
};

export default ContactUsPage;
