import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import axios from 'axios';

const FORM_URL = 'https://us-central1-hillside-site.cloudfunctions.net/contact'
const API_KEY = 'CWr7ITzPMrrWPCbq5DS5fD3F2O5Os0KLTZdFMriw6jI';

const ContactForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [notes, setNotes] = useState('');
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    phone: null,
    website: null,
    notes: null,
  });

  const checkInputs = () => {
    let valid = true;
    const newErrors = {};

    if (name.length < 1) {
      newErrors['name'] = 'Please enter your name';
      valid = false;
    }

    if (email.length < 1) {
      newErrors['email'] = 'Please enter your email';
      valid = false;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors({ ...errors, ...newErrors });
    } else {
      setErrors({
        name: null,
        email: null,
        phone: null,
        website: null,
        notes: null,
      });
    }

    return valid;
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'website':
        setWebsite(e.target.value);
        break;
      case 'notes':
        setNotes(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const valid = checkInputs();

    if (valid) {
      setSubmitting(true);

      const res = await axios.post(FORM_URL, { API_KEY, name, email, phone, website, notes });

      if (res.status === 200) {
        setShowMessage(true);

        setName('');
        setEmail('');
        setPhone('');
        setWebsite('');
        setNotes('');
        setErrors({
          name: null,
          email: null,
          phone: null,
          website: null,
          notes: null,
        });
        setSubmitting(false);
      } else {
        setSubmitting(false);
      }
    }
  };

  return (
    <div className="bg-[#F0FDFC] py-8 px-4 lg:flex lg:px-24 lg:gap-x-16 lg:place-items-center">
      <div className="bg-white mx-auto rounded-xl py-16 px-4 lg:rounded-2xl">
        <h1 className="text-[#3C0F76] text-4xl text-center font-serif">
          Ready to bring your idea to life?
        </h1>

        <h2 className="mt-4 font-semi text-[#FFB000] bold text-center">
          We're excited to kick things off!
        </h2>

        <form className="mt-12 w-100 text-center">
          <input
            className="my-4 appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="name"
            type="text"
            value={name}
            onChange={handleChange}
            placeholder="Your Name"
            required
          />
          {errors.name ? (
            <p className="text-[#FB3640] text-sm text-right">{errors.name}</p>
          ) : (
            <p className="text-[#FB3640] text-sm text-right invisible">{errors.name}</p>
          )}

          <input
          className="my-4 appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="email"
            type="email"
            value={email}
            onChange={handleChange}
            placeholder="Your Email"
            required
          />
          {errors.email ? (
            <p className="text-[#FB3640] text-sm text-right">{errors.email}</p>
          ) : (
            <p className="text-[#FB3640] text-sm text-right invisible">{errors.email}</p>
          )}

          <input
            className="my-4 appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="phone"
            type="phone"
            value={phone}
            onChange={handleChange}
            placeholder="Your Phone (optional)"
          />
          {errors.phone ? (
            <p className="text-[#FB3640] text-sm text-right">{errors.phone}</p>
          ) : (
            <p className="text-[#FB3640] text-sm text-right invisible">{errors.phone}</p>
          )}

          <input
            className="my-4 appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="website"
            type="text"
            value={website}
            onChange={handleChange}
            placeholder="Your Website (optional)"
          />
          {errors.website ? (
            <p className="text-[#FB3640] text-sm text-right">{errors.website}</p>
          ) : (
            <p className="text-[#FB3640] text-sm text-right invisible">{errors.website}</p>
          )}

          <textarea
            className="my-4 appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="notes"
            type="text"
            value={notes}
            onChange={handleChange}
            placeholder="Tell us what you're looking for..."
            rows="3"
          />
          {errors.notes ? (
            <p className="text-[#FB3640] text-sm text-right">{errors.notes}</p>
          ) : (
            <p className="text-[#FB3640] text-sm text-right invisible">{errors.notes}</p>
          )}

          <button
            type="submit"
            className="mt-6 bg-[#FB3640] border-none rounded-full text-base px-8 py-2 lg:px-12 lg:py-2 lg:font-semibold text-white hover:bg-[#E13039] hover:text-white disabled:bg-[#FC8187] disabled:text-gray disabled:shadow-none"
            onClick={handleSubmit}
            disabled={submitting}
          >
            Submit
          </button>

          {showMessage && <p className="mt-4">Thank you, we'll be in contact soon.</p>}
        </form>
      </div>

      <div className="w-100 px-8">
        <StaticImage
          src="../images/bg-hero-mobile.png"
          alt="Background image of a man on a boat"
        />
      </div>
    </div>
  );
};

export default ContactForm;
